import { Component, Vue, Prop } from 'vue-property-decorator'

import { UrlBase } from '@/config';

import { Process } from '@/store/modules/process/process';
import { IconListOptions } from '@/components/wrappers/molds/IconListItem';import { Alert } from '@/services/notification';

import ModalOperacao from './modalOperacao/ModalOperacao.vue'

//confing
import { isEmpty } from '@/utils';

//services
import { ProcessService } from '@/services/processService'

//wrappers
import {
	WColumns,
	WColumn,
	wIcon,
	Arrow,
	IconList,
	SizedBox
} from '@/components/wrappers';

@Component({
	components: {
		WColumns,
		WColumn,
		wIcon,
		Arrow,
		IconList,
		SizedBox
	}
})
export default class HistoryProcess extends Vue {
	@Prop() process!: Process;
  activeDropdown = false;
  processService = new ProcessService(this.$store)

  isShowMore = false
  processDetails = ''

  mounted() {
    console.log('mounted');
    this.processDetails = this.process.details;
    console.log(this.processDetails.length);
    if (this.processDetails.length > 20) {
      console.log(this.processDetails.substring(20));
      this.processDetails = this.processDetails.substring(20) + '...';
    }
  }

  getStatusStr(status: any){
    return this.processService.getStatusName(status)
  }
  // http://gestor.espiritocidadao.org:8088/
  // opção comentar, solucionar e araquivar só estão funcionando com a opção público ativa
  // a opção retornar e receber não funciona nenhuma das duas opções
  // a opção anexar está normal
  // e encaminhar tbm não funciona
  // Comentar, soluciocar, arquivar: Só funciona com a opção de público ligada receber, retornar e encaminhar: Não funciona nenhuma opção Anexar e detalhes: Funcionando normal
  sendGenericOperation(operation: string, title: string){
    const onConfirm = (modalData: any) => {
      const idGroup = this.process.idGroupLocal;
      const idProcess = this.process.idProcess;
      let publicProc = "0";
      if(modalData.public){
        publicProc = "1"
      }
      let data: any = {
        "description": modalData.comentario,
        "public": publicProc,
        "notify":0
      }
      if( operation == 'forward' ){
        data.idGroupDestiny = modalData.selectedGroup.idGroup;
        this.$router.push('/home')
      }
      if( operation == 'comment/files' ){
        data = new FormData();
        modalData.files.map( (file: File, i: number) => {
          data.append('file'+i, file);
        })
        data.append('title', title);
        data.append('description', modalData.comentario);
        data.append('notify', '0');
        data.append('public', '0');
      }
      console.log(data);
      
      // return;
      this.processService.dispatchGenericOperationProcess(idGroup, idProcess, data, operation);
    }
    const configModal = {
      modalTitle: title,
      operation: operation
    }
    this.openModal(configModal, onConfirm);
  }

  openModal(config: any, onConfirm: Function): void {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ModalOperacao,
      customClass: '',
      width: '470',
      props: {
        modalTitle: config.modalTitle,
        operation: config.operation
      },
      events: {
        confirm: (modalData: object) => {
          onConfirm(modalData);
          modal.close()
        }
      }
    })
    
  }

  clickBtnMoreDetails(){
    this.$router.push(`/details/${this.process.idProcess}/${this.process.idGroupLocal}`)
  }

  getUrlFile(file: any){
    return UrlBase()+file.file
  }

  isShowType(process: any){
    const res = this.arrTypesManifest.find( (item: any) => {
      return item.type == process.type
    })
    return res != undefined? res: false;
  }

  getTypeTitle(process: any){
    const res = this.arrTypesManifest.find( (item: any) => {
      return item.type == process.type
    })
    return res != undefined? res.title: false;
  }

  arrTypesManifest = [
    { type: 'denuncia', title: 'Denúncia' },
    { type: 'reclamacao', title: 'Reclamação' },
    { type: 'solicitacao', title: 'Solicitação' },
    { type: 'sugestao', title: 'Sugestão' },
    { type: 'elogio', title: 'Elogio' }
  ]

	iconListInto: IconListOptions = {
		class: 'theme2-c is-size-5 has-text-centered',
		IconListItens: [
			{
				icon: 'mdi mdi-file-replace',
				title:'Apensar'
			},
			{
				icon: 'mdi mdi-file-refresh',
				title:'Desapensar'
			},
			{
				icon: 'mdi mdi-message-text',
				title:'Comentar'
			},
			{
				icon: 'mdi mdi-share',
				title: 'Encaminhar',
			},
			{
				icon: 'mdi mdi-file-cabinet',
				title:'Arquivar'
			},
			{
				icon: 'mdi mdi-eye',
				title:'Acompanhar'
			},
			{
				icon: 'mdi mdi-printer',
				title:'Imprimir'
			},
			{
				icon: 'mdi mdi-tag-text',
				title:'Imprimir no formato de etiqueta'
			},
			
		]
	}

}
