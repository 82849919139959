import { Component, Vue, Prop } from 'vue-property-decorator';
import debounce from 'lodash/debounce'

//request
import { api, apiFormData } from '@/services/api'

//models
import { ListPanelModel } from '@/molds/ListPanelModel';
import { FieldSearchModel } from '@/molds/FieldSearchModel';

//components
import ListPanel from '@/components/listPanel/ListPanel.vue'

//services
import { Alert } from '@/services/notification';

import {
	WColumns,
	WColumn,
	wIcon,
	WButton,
	Loader
} from '@/components/wrappers';

@Component({
	components: {
		WColumns,
		WColumn,
		ListPanel,
		wIcon,
		WButton,
		Loader
	}
})

export default class ModalSeach extends Vue {
	// props
	@Prop() modalTitle!: string;
	@Prop() operation!: string;

	alert = new Alert(this.$store)

	// data
	msgComentario = '';
  isPublic = false

  close(){
    this.$emit('close');
  }

  clickBtnConfirm(){
    const modalData = {
      comentario: this.msgComentario,
      selectedGroup: this.selectedGroup,
      files: this.dropFiles,
      public: this.isPublic
    }
    this.$emit('confirm', modalData)
  }

  groupList: Array<any> = []
  selectedGroup = {}
  isFetching = false

  get selectedAutocomplete(){
    return this.selectedGroup;
  }

  set selectedAutocomplete(newValue: any){
    if(newValue==null){
      this.selectedGroup = {};
    }
    else{
      this.selectedGroup = newValue;
    }
  }

  getAsyncData = debounce( (name: any) => {
    if (!name.length) {
      this.setGroupList([])
      this.selectedAutocomplete = null
      return
    }
    this.isFetching = true
    api.get(`/group?name=${name}`)
      .then((response) => {
        this.setGroupList(response.data.list)
      })
      .catch((error) => {
        this.setGroupList([])
        throw error
      })
      .finally(() => {
        this.isFetching = false
      })
  }, 200)

  setGroupList(newValue: any){
    console.log('setTest', newValue);
    this.groupList = newValue;
  }


  dropFiles: any = []

  deleteDropFile(index: any) {
    this.dropFiles.splice(index, 1)
  }
  

}
